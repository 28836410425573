import register from 'preact-custom-element';
import Select, { components } from 'react-select';

const CustomOption = (props: any) => {
  const {
    innerProps,
    isDisabled,
    label,
    data: { swatchName },
  } = props;
  return !isDisabled ? (
    <div
      {...innerProps}
      data-swatch={swatchName}
      className="product-card__colours-dropdown__option"
    >
      <span className="product-card__colours-dropdown__swatch-icon"></span>
      <span className="product-card__colours-dropdown__option-label">
        {label}
      </span>
    </div>
  ) : null;
};

const SelectedOption = (props: any) => {
  const selectedValue = props.getValue()[0];
  const { label, swatchName } = selectedValue;
  return (
    <components.SingleValue {...props}>
      <div
        className="product-card__colours-dropdown__selected-option"
        data-swatch={swatchName}
      >
        <span className="product-card__colours-dropdown__swatch-icon"></span>
        <span className="product-card__colours-dropdown__option-label">
          {label}
        </span>
      </div>
    </components.SingleValue>
  );
};
function ProductCardColoursDropdown(props: any) {
  const stitchedProducts = JSON.parse(props.stitchedProducts);
  const defaultProductId = JSON.parse(props.defaultProductId);
  const formId = props.formId;

  const options = stitchedProducts.map((stitchedProduct: any) => {
    const colour = stitchedProduct.title.split(' | ')[1];
    return {
      label: colour,
      value: stitchedProduct.id,
      swatchName: colour.replace('"').toLowerCase(),
      isDefault: stitchedProduct.id === defaultProductId,
    };
  });

  const defaultOption = options.find((option: any) => !!option?.isDefault);

  const onSelectChange = (option: any) => {
    const hiddenSelect = document.querySelector(
      `#${formId} select[name='product-id']`
    ) as any;
    hiddenSelect.value = option.value;
    const event = new Event('change');
    hiddenSelect.dispatchEvent(event);
  };

  return (
    <div className="product-card__colours-dropdown flex w-full items-center">
      <label
        htmlFor="product-colour-dropdown-select"
        className="w-[62px] text-black"
      >
        Colour
      </label>
      <Select
        options={options}
        defaultValue={defaultOption}
        components={{
          Option: CustomOption,
          IndicatorSeparator: () => null,
          SingleValue: SelectedOption,
        }}
        isSearchable={false}
        className="product-card__colours-dropdown__select"
        classNames={
          {
            control: () => 'product-card__colours-dropdown__control border-none',
            menu: () => 'product-card__colours-dropdown__menu',
            valueContainer: () =>
              'product-card__colours-dropdown__value-container',
            indicatorsContainer: () =>
              'product-card__colours-dropdown__indicators-container',
          } as any
        }
        id="product-colour-dropdown-select"
        onChange={onSelectChange}
      />
    </div>
  );
}

register(ProductCardColoursDropdown, 'product-card-colours-dropdown', [
  'stitched-products',
  'form-id',
  'default-product-id',
]);
