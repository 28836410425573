import { useWishlist } from '@/lib/swym/hooks/useWishlist';
import register from 'preact-custom-element';
import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'preact/hooks';

type ZendeskButtonProps = {
  text: string;
};

function ZendeskButton({ text = 'Instant Chat' }: ZendeskButtonProps) {
  const handleClick = useCallback(() => {
    if (!window.zE) return;
    window.zE('webWidget', 'open');
  }, []);

  return (
    <button
      className="h-12 w-full border-0 border-t-[1px] border-solid border-lightGreySand text-center hover:bg-transparent"
      onClick={handleClick}
    >
      {text}
    </button>
  );
}

register(ZendeskButton, 'zendesk-button', ['text']);
